

















import { getSkuIdList } from '@/api/product';
import { IBaseShoppingCartItem, IBaseStand, PayTypeEnum, ProductTypeEnum } from '@/api/type/base';
import { GetPromiseReturn } from '@/api/type/tool';
import { AddressItem } from '@/api/user/address';
import { Vue, Component } from 'vue-property-decorator';
import ConfirmAddress from './comm/ConfirmAddress.vue';
import ConfirmAccount from './comm/ConfirmAccount.vue';
import ConfirmStandCard from './comm/ConfirmStandCard.vue';
import { Toast } from 'vant';
import { createOrder } from '@/api/pay';
import router from '@/router';

type ISkuList = GetPromiseReturn<typeof getSkuIdList>['payload'];
type GCreateOrderInfo = Pick<IBaseShoppingCartItem, 'skuId' | 'amount'>;
@Component({
  name: '',
  components: { ConfirmAddress, ConfirmAccount, ConfirmStandCard }
})
export default class extends Vue {
  /* 地址Id */
  addressId: AddressItem['addressId'] = '';
  /* 充值账号信息 */
  rechargeAccount = '';
  /* 商品类型 */
  productType?: ProductTypeEnum = undefined;
  /* 规格详情 */
  skuList: ISkuList = [];
  payType: PayTypeEnum = PayTypeEnum.PAY_TYPE_WECHAT;

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  async onBuy() {
    if (this.productType === this.productTypeEnum.PRODUCT_TYPE_PHYSICAL && !this.addressId) {
      Toast('未添加收货地址');
      return;
    }

    await createOrder({
      skuList: this.createOrderInfo.map(item => ({
        skuId: item.skuId,
        num: item.amount
      })),
      payType: this.payType,
      addressId: this.addressId,
      rechargeAccount: this.rechargeAccount,
      orderCoupon: {
        couponNumber: this.couponInfo.couponNumber,
        couponPassword: this.couponInfo.couponPassword
      }
    });

    Toast({
      type: 'success',
      message: '兑换成功'
    });
    router.replace({ name: 'OrderList' });
  }

  async getSkuIdList(skuIds: IBaseStand['skuId'][]) {
    const { payload } = await getSkuIdList(skuIds);
    this.skuList = payload;
  }

  /* 创建订单传递过来的参数 */
  get createOrderInfo(): GCreateOrderInfo[] {
    return JSON.parse(this.$route.query.skuInfo as any) as GCreateOrderInfo[];
  }

  /* 创建订单传递过来的兑换券信息 */
  get couponInfo() {
    return JSON.parse(this.$route.query.couponData as any);
  }

  async created() {
    this.productType = this.$route.query.productType as ProductTypeEnum;
    await this.getSkuIdList(this.createOrderInfo.map(item => item.skuId));
  }
}
